import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    supplier: {},
    supplierList: [],
  };


  const slice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      getSupplierSuccess(state, action) {
        state.isLoading = false;
        state.supplierList = action.payload;
      },
  
      getSupplierById(state, action) {
        state.isLoading = false;
        state.supplier = action.payload;
      },

      addSupplierSuccess(state,action){
        state.isLoading = false;
      }
    },
  });
  
  // Reducer
  export default slice.reducer;
  
  export function getSuppliers() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/suppliers');
        dispatch(slice.actions.getSupplierSuccess(response?.data?.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  export function getSupplierById(id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/api/suppliers/${id}`);
        dispatch(slice.actions.getSupplierById(response.data.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addSupplier(data) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/api/suppliers', data);
        dispatch(slice.actions.addSupplierSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateSupplier(id,data) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/suppliers/${id}`, data);
        dispatch(slice.actions.addSupplierSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }


  


 
  